import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import {
  accessType,
  articleTypes,
  selectStatusoftheArticle,
} from "../../../config";
import { Form, Button, Col, Row } from "react-bootstrap";

const UserCreateArticles = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const loginData: any = sessionStorage.getItem("loginData");
  const userData = JSON.parse(loginData);
  const [formData, setFormData] = useState<any>({
    description: "",
    articlejournalName: "",
    month: "",
    year: "",
    articleType: "",
    publishDate: "",
    accessType: "",
    title: "",
    keywords: "",
    abstract: "",
    startPage: "",
    endPage: "",
    issn: "",
    volume: "",
    issue: "",
    supplementaryLink: "",
    universityName: "",
    countryName: "",
    authorNames: "",
    authorUrl: "",
    photo: null,
    selectStatusoftheArticle: "",
    doiNumber: "",
    doiLink: "",
    jkeywords: "",
    metaTitle:"",
    metaDescription:"",
    slug:""
  });
  const [errors, setErrors] = useState<any>({});

  useEffect(() => {
    getJournalNames();
  }, []);

  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData();
    setFormData({
      ...formData,
      abstract: data,
    });
  };

  const getJournalNames = async () => {
    try {
      const response = await AdminService.allJournalNames("");
      setData(response.data);
    } catch (error) {
      console.error("Error fetching journal names:", error);
    }
  };

  const handleSelectChange = (selectedOption: any, { name }: any) => {
    if (name === "articlejournalName") {
      setFormData((prevData: any) => ({
        ...prevData,
        articlejournalName: selectedOption ? selectedOption.id : "",
      }));
    } else {
      setFormData((prevData: any) => ({
        ...prevData,
        [name]: selectedOption ? selectedOption.value : "",
      }));
    }
    setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
  };

  const handleInput = (e: any) => {
    const { name, value, type, files } = e.target;
    setFormData((prevData: any) => ({
      ...prevData,
      [name]: type === "file" ? files[0] : value,
    }));
    setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
  };

  const validate = () => {
    const newErrors: any = {};
    if (!formData.selectStatusoftheArticle)
      newErrors.selectStatusoftheArticle =
        "selectStatusoftheArticle is required";
    if (!formData.description)
      newErrors.description = "Description is required";
    if (!formData.month) newErrors.month = "Month is required";
    if (!formData.year) newErrors.year = "Year is required";
    if (!formData.publishDate)
      newErrors.publishDate = "Publish Date is required";
    if (!formData.articleType)
      newErrors.articleType = "Article Type is required";
    if (!formData.accessType) newErrors.accessType = "Access Type is required";
    // Additional validations can be added as needed
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = (e: any) => {
    e.preventDefault();
    const loginData: any = sessionStorage.getItem("loginData");
    const data = JSON.parse(loginData);
    if (userData?.role?.roleName === "user") {
      formData.articlejournalName = data?.journalName?._id;
    }

    if (validate()) {
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      AdminService.createArticle(formDataToSend).then((res) => {
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          toast.success(res.message);
          navigate("/user/articlesList");
        }
      });
    }
  };

  const handleCancel = () => {
    navigate("/user/articlesList");
  };

  const articleTypeOptions = articleTypes.map((type) => ({
    value: type,
    label: type,
  }));
  const accessTypeOptions = accessType.map((type) => ({
    value: type,
    label: type,
  }));
  const selectStatusoftheArticleOptions = selectStatusoftheArticle.map(
    (type) => ({ value: type, label: type })
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 className="text-left">
            <b>Add New Articles</b>
          </h4>
          <Form onSubmit={handleRegister}>
            <Row>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>Select Status of the Article *</Form.Label>
                  <Select
                    options={selectStatusoftheArticleOptions}
                    name="selectStatusoftheArticle"
                    placeholder="Select Status Of the Article"
                    onChange={handleSelectChange}
                    value={selectStatusoftheArticleOptions.find(
                      (option) =>
                        option.value === formData.selectStatusoftheArticle
                    )}
                  />
                  {errors.selectStatusoftheArticle && (
                    <div className="invalid-feedback d-block">
                      {errors.selectStatusoftheArticle}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>Volume and Issue No:</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    name="description"
                    value={formData.description}
                    onChange={handleInput}
                    isInvalid={!!errors.description}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>Issue Releasing Month *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Month"
                    name="month"
                    value={formData.month}
                    onChange={handleInput}
                    isInvalid={!!errors.month}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.month}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>Year *</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Year"
                    name="year"
                    value={formData.year}
                    onChange={handleInput}
                    isInvalid={!!errors.year}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.year}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mt-3">
                  <Form.Label>Article Type *</Form.Label>
                  <Select
                    options={articleTypeOptions}
                    name="articleType"
                    placeholder="Select Article Type"
                    onChange={handleSelectChange}
                    value={articleTypeOptions.find(
                      (option) => option.value === formData.articleType
                    )}
                  />
                  {errors.articleType && (
                    <div className="invalid-feedback d-block">
                      {errors.articleType}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mt-3">
                  <Form.Label>Select Article Publish Date *</Form.Label>
                  <Form.Control
                    type="date"
                    name="publishDate"
                    value={formData.publishDate}
                    onChange={handleInput}
                    isInvalid={!!errors.publishDate}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.publishDate}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mt-3">
                  <Form.Label>Select Access Type *</Form.Label>
                  <Select
                    options={accessTypeOptions}
                    name="accessType"
                    placeholder="Select Access Type"
                    onChange={handleSelectChange}
                    value={accessTypeOptions.find(
                      (option) => option.value === formData.accessType
                    )}
                  />
                  {errors.accessType && (
                    <div className="invalid-feedback d-block">
                      {errors.accessType}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mt-3">
                  <Form.Label>Article Title</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Article Title"
                    name="title"
                    value={formData.title}
                    onChange={handleInput}
                    isInvalid={!!errors.title}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.title}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mt-3">
                  <Form.Label>
                    Enter Journal Classification Keywords [Journal Highlights]
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder=""
                    name="jkeywords"
                    value={formData.jkeywords}
                    onChange={handleInput}
                    isInvalid={!!errors.jkeywords}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.jkeywords}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mt-3">
                  <Form.Label>Search Author Related URL</Form.Label>
                  <Form.Control
                    type="text"
                    name="authorUrl"
                    value={formData.authorUrl}
                    onChange={handleInput}
                    isInvalid={!!errors.authorUrl}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.authorUrl}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mt-3">
                  <Form.Label>Author Names</Form.Label>
                  <Form.Control
                    type="text"
                    name="authorNames"
                    value={formData.authorNames}
                    onChange={handleInput}
                    isInvalid={!!errors.authorNames}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.authorNames}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>University Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="universityName"
                    value={formData.universityName}
                    onChange={handleInput}
                    isInvalid={!!errors.universityName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.universityName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>Country Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="countryName"
                    value={formData.countryName}
                    onChange={handleInput}
                    isInvalid={!!errors.countryName}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.countryName}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>Doi Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="doiNumber"
                    value={formData.doiNumber}
                    onChange={handleInput}
                    isInvalid={!!errors.doiNumber}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.doiNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>Doi Link</Form.Label>
                  <Form.Control
                    type="text"
                    name="doiLink"
                    value={formData.doiLink}
                    onChange={handleInput}
                    isInvalid={!!errors.doiLink}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.doiLink}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>Meta Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="metaTitle"
                    value={formData.metaTitle}
                    onChange={handleInput}
                    placeholder="Meta Title"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>Meta Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="metaDescription"
                    value={formData.metaDescription}
                    onChange={handleInput}
                    placeholder="Meta Description"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>Slug</Form.Label>
                  <Form.Control
                    type="text"
                    name="slug"
                    value={formData.slug}
                    onChange={handleInput}
                    placeholder="Slug"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>keywords</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder=""
                    name="keywords"
                    value={formData.keywords}
                    onChange={handleInput}
                    isInvalid={!!errors.keywords}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.keywords}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mt-3">
                  <Form.Label>Abstract Content</Form.Label>
                  <div className="custom-editor-height">
                    <CKEditor
                      editor={ClassicEditor}
                      data={formData.abstract}
                      onChange={handleEditorChange}
                    />
                  </div>

                  {errors.abstract && (
                    <div className="invalid-feedback d-block">
                      {errors.abstract}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mt-3">
                  <Form.Label>Starting Page Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="startPage"
                    value={formData.startPage}
                    onChange={handleInput}
                    isInvalid={!!errors.startPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.startPage}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mt-3">
                  <Form.Label>Ending Page Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="endPage"
                    value={formData.endPage}
                    onChange={handleInput}
                    isInvalid={!!errors.endPage}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.endPage}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mt-3">
                  <Form.Label>ISSN</Form.Label>
                  <Form.Control
                    type="text"
                    name="issn"
                    value={formData.issn}
                    onChange={handleInput}
                    isInvalid={!!errors.issn}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.issn}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mt-3">
                  <Form.Label>Volume</Form.Label>
                  <Form.Control
                    type="text"
                    name="volume"
                    value={formData.volume}
                    onChange={handleInput}
                    isInvalid={!!errors.volume}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.volume}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mt-3">
                  <Form.Label>Issue</Form.Label>
                  <Form.Control
                    type="text"
                    name="issue"
                    value={formData.issue}
                    onChange={handleInput}
                    isInvalid={!!errors.issue}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.issue}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={9}>
                <Form.Group className="mt-3">
                  <Form.Label>Supplementary Link</Form.Label>
                  <Form.Control
                    type="text"
                    name="supplementaryLink"
                    value={formData.supplementaryLink}
                    onChange={handleInput}
                    isInvalid={!!errors.supplementaryLink}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.supplementaryLink}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mt-3">
                  <Form.Label>Browse Photo</Form.Label>
                  <Form.Control
                    type="file"
                    name="photo"
                    onChange={handleInput}
                    isInvalid={!!errors.photo}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.photo}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <div className="mt-3">
              <Button type="submit" variant="primary" className="me-2">
                Create
              </Button>
              <Button type="button" variant="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default UserCreateArticles;
