import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useAppContext } from "../components/context/AppContext";
import ProtectedRoute from "./ProtectedRoute";

// Import Pages
import HomePage from "../pages/home/HomePage";
import AboutUsPage from "../pages/aboutus/AboutUsPage";
import AuthorGuidelinesPage from "../pages/guidelines/AuthorGuidelinesPage";
import Manuscriptpage from "../pages/manuscript/manuscriptpage";
import ContactusPage from "../pages/contactus/ContactusPage";
import ReviewerGuidelinesPage from "../pages/guidelines/ReviewerGuidelinesPage";
import EditorGuidelinesPage from "../pages/guidelines/EditorGuidelinesPage";
import SupportusPage from "../pages/supportus/SupportusPage";
import JournalsPage from "../pages/Journals/JournalsPage";
import ViewAllJournalsPage from "../pages/Journals/ViewAllJournalsPage";
import { Login } from "../components/login/Login";
import { Dashboard } from "../components/dashboard";
import JournalsList from "../components/adminDashboard/Journals/JournalsList";
import AimsPage from "../pages/Journals/AimsPage";
import RegisterNow from "../components/adminDashboard/user/createUser";
import CreateJournal from "../components/adminDashboard/Journals/createJournal";
import ManuscriptList from "../components/adminDashboard/manuscript/manuscriptList";
import ArticlesList from "../components/adminDashboard/articles/ArticlesList";
import CreateArticles from "../components/adminDashboard/articles/CreateArticles";
import EditorList from "../components/adminDashboard/editor/EditorList";
import CreateEditorBoard from "../components/adminDashboard/editor/CreateEditorBoard";
import JournalsDataList from "../components/adminDashboard/JournalsData/JournalsDataList";
import CreateJournalData from "../components/adminDashboard/JournalsData/createJournalData";
import JournalsDetailsPage from "../pages/Journals/JournalsDetailsPage";
import EditorDetailsPage from "../pages/Journals/editorDetailsPage";
import UsersList from "../components/adminDashboard/user/UsersList";
import UserArticlesList from "../components/userDashboard/userArticles/UserArticlesList";
import UserJournalsList from "../components/userDashboard/userJournals/UserJournalsList";
import UserEditorialBoardList from "../components/userDashboard/userEditorialboard/UserEditorialBoardList";
import UserJournalCreate from "../components/userDashboard/userJournals/UserJournalCreate";
import UserCreateArticles from "../components/userDashboard/userArticles/UserCreateArticles";
import UserCreateEditorialBoard from "../components/userDashboard/userEditorialboard/UserCreateEditorialBoard";
import UserJournalEdit from "../components/userDashboard/userJournals/UserJournalEdit";
import UserEditorialBoard from "../components/userDashboard/userEditorialboard/UserEditorialBoard";
import UserEditArticles from "../components/userDashboard/userArticles/UserEditArticles";
import ContactUsList from "../components/adminDashboard/contactUs/contactUsList";
import OpenAccessPage from "../pages/openAccess/OpenAccessPage";
import ReviewProcessPage from "../pages/reviewProcess/ReviewProcessPage";
import EditorJoiningPage from "../components/guidelines/EditorJoiningPage";
import EditorJoiningList from "../components/adminDashboard/editorJoining/editorJoiningList";
import EditUser from "../components/adminDashboard/user/EditUser";
import EditJournal from "../components/adminDashboard/Journals/editJournals";
import EditJournalData from "../components/adminDashboard/JournalsData/editJournalData";
import EditArticles from "../components/adminDashboard/articles/editArticles";
import EditorBoard from "../components/adminDashboard/editor/EditorBoard";
import AbstractPage from "../pages/Journals/abstractPage";
import ArticleInPressPage from "../pages/Journals/articleInPressPage";
import CurrentIssuePage from "../pages/Journals/CurrentIssuePage";
import ArchivePage from "../pages/Journals/archivePage";
import PreviousIssuesList from "../components/userDashboard/userIssues/PreviousIssuesList";
import CurrentIssuesList from "../components/userDashboard/userIssues/CurrentIssuesList";
import SpecialissuesPage from "../pages/Journals/specialissuesPage";
import HeaderImagesList from "../components/adminDashboard/HeaderImages/HeaderImagesList";
import CreateHeaderImage from "../components/adminDashboard/HeaderImages/CreateHeaderImage";
import EditHeaderImage from "../components/adminDashboard/HeaderImages/EditHeaderImage";
import ArchiveDataPage from "../pages/Journals/archiveDataPage";
import OpenEditorJoining from "../components/adminDashboard/editorJoining/openEditorJoining";
import { NotFound } from "../components/not-found";
import AuthorDataPage from "../pages/guidelines/AuthorDataPage";
import ManuscriptDatapage from "../pages/manuscript/manuscriptDatapage";


export const RootNavigation = () => {
  const { usersData }: any = useAppContext();
  console.log("userData", usersData);

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<HomePage />} />
      {/* <Route path="/home" element={<HomePage />} /> */}
      <Route path="/aboutus" element={<AboutUsPage />} />
      <Route path="/author-guideliness" element={<AuthorGuidelinesPage />} />
      <Route path="/submission-page-urls" element={<Manuscriptpage />} />
      <Route path="/submission-page-url/:journalTitle" element={<ManuscriptDatapage />} />

      
      <Route path="/reviewer-guidelines" element={<ReviewerGuidelinesPage />} />
      <Route path="/editor-guidelines" element={<EditorGuidelinesPage />} />
      <Route path="/contact-us" element={<ContactusPage />} />
      <Route path="/open-Access" element={<SupportusPage />} />
      <Route path="/journals" element={<JournalsPage />} />
      <Route path="/viewAllJournals" element={<ViewAllJournalsPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/journalsAims/:journalTitle" element={<AimsPage />} />
      <Route path="/journal-details/:journalTitle" element={<JournalsDetailsPage />} />
      <Route path="/editor-journal-details/:journalTitle" element={<EditorDetailsPage />} />
      <Route path="/open-access/:journalTitle" element={<OpenAccessPage />} />
      <Route path="/review-process" element={<ReviewProcessPage />} />
      <Route path="/editorjoiningpage" element={<EditorJoiningPage />} />
      <Route path="/abstract/:abstractId" element={<AbstractPage />} />
      <Route path="/article-in-press/:journalTitle" element={<ArticleInPressPage />} />
      <Route path="/current-issue/:journalTitle" element={<CurrentIssuePage />} />
      <Route path="/archives/:journalTitle" element={<ArchivePage />} />
      <Route path="/special-issues/:journalTitle" element={<SpecialissuesPage />} />
      <Route path="/author-guidelines/:journalTitle" element={<AuthorDataPage />} />

      
      <Route path="/archiveData" element={<ArchiveDataPage />} />

      {/* Admin Dashboard */}
      <Route
        path="/admin/journalsList"
        element={
          <ProtectedRoute component={JournalsList} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/journalsList"
        element={
          <ProtectedRoute component={JournalsList} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/createuser"
        element={
          <ProtectedRoute component={RegisterNow} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/userList"
        element={<ProtectedRoute component={UsersList} requiredRole="admin" />}
      />
      <Route
        path="/admin/editUser"
        element={<ProtectedRoute component={EditUser} requiredRole="admin" />}
      />
      <Route
        path="/admin/editJournal"
        element={
          <ProtectedRoute component={EditJournal} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/editJournalData"
        element={
          <ProtectedRoute component={EditJournalData} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/OpenEditorJoining"
        element={
          <ProtectedRoute component={OpenEditorJoining} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/editArticles"
        element={
          <ProtectedRoute component={EditArticles} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/editorBoard"
        element={
          <ProtectedRoute component={EditorBoard} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/HeaderImagesList"
        element={
          <ProtectedRoute component={HeaderImagesList} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/CreateHeaderImage"
        element={
          <ProtectedRoute component={CreateHeaderImage} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/editHeaderImage"
        element={
          <ProtectedRoute component={EditHeaderImage} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/createJournal"
        element={
          <ProtectedRoute component={CreateJournal} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/manuscriptList"
        element={
          <ProtectedRoute component={ManuscriptList} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/articlesList"
        element={
          <ProtectedRoute component={ArticlesList} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/createArticles"
        element={
          <ProtectedRoute component={CreateArticles} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/journalsDataList"
        element={
          <ProtectedRoute component={JournalsDataList} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/editorialList"
        element={<ProtectedRoute component={EditorList} requiredRole="admin" />}
      />
      <Route
        path="/admin/createEditorial"
        element={
          <ProtectedRoute component={CreateEditorBoard} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/createJournalData"
        element={
          <ProtectedRoute component={CreateJournalData} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/contactUsList"
        element={
          <ProtectedRoute component={ContactUsList} requiredRole="admin" />
        }
      />
      <Route
        path="/admin/editorJoiningList"
        element={
          <ProtectedRoute component={EditorJoiningList} requiredRole="admin" />
        }
      />

      {/* User Dashboard */}
      <Route
        path="/user/articlesList"
        element={
          <ProtectedRoute component={UserArticlesList} requiredRole="user" />
        }
      />
      <Route
        path="/user/journalsList"
        element={
          <ProtectedRoute component={UserJournalsList} requiredRole="user" />
        }
      />
      <Route
        path="/user/editorialList"
        element={
          <ProtectedRoute
            component={UserEditorialBoardList}
            requiredRole="user"
          />
        }
      />
      <Route
        path="/user/createjournal"
        element={
          <ProtectedRoute component={UserJournalCreate} requiredRole="user" />
        }
      />
      <Route
        path="/user/createarticle"
        element={
          <ProtectedRoute component={UserCreateArticles} requiredRole="user" />
        }
      />
      <Route
        path="/user/createeditorialboard"
        element={
          <ProtectedRoute
            component={UserCreateEditorialBoard}
            requiredRole="user"
          />
        }
      />
      <Route
        path="/user/editjournal"
        element={
          <ProtectedRoute component={UserJournalEdit} requiredRole="user" />
        }
      />
      <Route
        path="/user/editorialboard"
        element={
          <ProtectedRoute component={UserEditorialBoard} requiredRole="user" />
        }
      />
      <Route
        path="/user/editarticles"
        element={
          <ProtectedRoute component={UserEditArticles} requiredRole="user" />
        }
      />
      <Route
        path="/user/previousIssues"
        element={
          <ProtectedRoute component={PreviousIssuesList} requiredRole="user" />
        }
      />
      <Route
        path="/user/currentIssues"
        element={
          <ProtectedRoute component={CurrentIssuesList} requiredRole="user" />
        }
      />

      {/* Redirect if no route matches */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
