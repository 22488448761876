import React, { useState, useEffect } from "react";
import Carousel from "react-bootstrap/Carousel";
import { toast } from "react-toastify";
import { AdminService } from "../../app/service/admin.service";
import { Col, Container, Row } from "react-bootstrap";
import SEO from "../SEO/SEO";

const CarouselComponent = () => {
  const [data, setData] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [seoData, setSeoData] = useState({
    title: "Welcome to Our Platform",
    description: "Explore our latest updates and visions.",
    slug: "/carousel",
  });

  useEffect(() => {
    getAllHeaderData();
  }, []);

  const getAllHeaderData = async () => {
    const requestData = {
      search: "",
      start: 1,
      count: 10,
    };

    try {
      const res = await AdminService.allHeaderData(requestData);
      if (res.status === "Success" && res.data?.length > 0) {
        setData(res?.data);
        updateSEO(res?.data[0]); // Set SEO for the first item initially
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  // Function to update SEO metadata
  const updateSEO = (item:any) => {
    setSeoData({
      title: item?.metaTitle || item?.title || "Our Vision & Mission",
      description: item?.metaDescription || item?.description || "Learn more about our platform and goals.",
      slug: `/carousel/${item?.slug || item?._id}`,
    });
  };

  // Handle carousel slide change
  const handleSelect = (selectedIndex:any) => {
    setActiveIndex(selectedIndex);
    updateSEO(data[selectedIndex]); // Update SEO based on active slide
  };

  return (
    <div className="carousel-data">
      {/* SEO Component for Dynamic Meta Tags */}
      <SEO title={seoData.title} description={seoData.description} slug={seoData.slug} />

      <Container>
        <Carousel activeIndex={activeIndex} onSelect={handleSelect} controls={false}>
          {data.map((item:any, index) => (
            <Carousel.Item key={item._id}>
              <Row>
                {/* Left Column: Content */}
                <Col md={8}>
                  <div className="mt-5 mb-3 bg-carousel-card pt-3 pb-3 d-flex align-items-center">
                    <h1
                      className="pt-5 px-4 py-4 carousel-des"
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    />
                  </div>
                  <div className="card-container">
                    <div className="card-content">
                      <h6 className="card-text">{item?.vision || "NA"}</h6>
                    </div>
                  </div>

                  <div className="bg-carousel-aim mt-5">
                    <div className="card-content">
                      <h6 className="aim-content">{item?.aim || "NA"}</h6>
                    </div>
                  </div>
                </Col>

                {/* Right Column: Image */}
                <Col md={4}>
                  <img
                    className="d-block mt-5"
                    src={item?.headerImage}
                    alt={item.description || "Carousel Image"}
                    style={{
                      height: "697px",
                      width: "427px",
                      objectFit: "contain",
                      borderRadius: "24px",
                    }}
                  />
                </Col>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default CarouselComponent;
