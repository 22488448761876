import React, { useEffect, useState } from "react";
import { Form, Button, Col, Row, Toast } from "react-bootstrap";
import Select from "react-select"; // Import react-select
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import { AdminService } from "../../../app/service/admin.service";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const UserEditorialBoard = () => {
  const [formData, setFormData] = useState<any>({
    editorName: "",
    editorAffiliation: "",
    editorDepartment: "",
    editorUniversityName: "",
    editorCountryName: "",
    editorType: "",
    editorBiography: "",
    editorResearchInterest: "",
    editorJournalName: "",
    editorContactNubmer: "",
    editorEmail: "",
    editorUrl: "",
    metaTitle: "",
    metaDescription: "",
    slug: "",
    editorImage: null, // New state for editor image
  });

  const loginData: any = sessionStorage.getItem("loginData");
  const userData = JSON.parse(loginData);

  const navigate = useNavigate();
  const location = useLocation(); // Get the passed state
  const [errors, setErrors] = useState<any>({});
  const [journalOptions, setJournalOptions] = useState<any[]>([]); // State to store journal options
  const [showToast, setShowToast] = useState(false); // State for toast notifications
  const [toastMessage, setToastMessage] = useState(""); // Message for toast notifications
  const [toastType, setToastType] = useState(""); // Type of toast (success/error)

  useEffect(() => {
    // Check if there's passed state data and set it in formData
    if (location.state) {
      setFormData(location.state);
    }
    getJournalNames(); // Fetch journal names on component mount
  }, [location.state]); // Add location.state as a dependency

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData: any) => ({ ...prevData, [name]: value }));
  };

  const handleImageChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      setFormData((prevData: any) => ({
        ...prevData,
        editorImage: e.target.files[0], // Set the image file to the formData
      }));
    }
  };

  const validate = () => {
    const newErrors: any = {};
    if (!formData.editorName) newErrors.editorName = "Editor Name is required";
    if (!formData.editorEmail)
      newErrors.editorEmail = "Editor Email is required";
    if (!formData.editorContactNubmer)
      newErrors.editorContactNubmer = "Editor Contact Number is required";
    if (!formData.editorAffiliation)
      newErrors.editorAffiliation = "Editor Affiliation is required";
    if (!formData.editorDepartment)
      newErrors.editorDepartment = "Editor Department is required";
    if (!formData.editorUniversityName)
      newErrors.editorUniversityName = "Editor University Name is required";
    if (!formData.editorCountryName)
      newErrors.editorCountryName = "Editor Country Name is required";
    if (!formData.editorType) newErrors.editorType = "Editor Type is required";
    if (!formData.editorImage)
      newErrors.editorImage = "Editor Image is required"; // Validate image upload
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const loginData: any = sessionStorage.getItem("loginData");
    const data = JSON.parse(loginData);
    formData.editorId = location?.state?._id;

    // editorId
    if (data?.role?.roleName === "user") {
      formData.editorJournalName = data?.journalName?._id;
    }

    if (validate()) {
      try {
        const formDataToSend = new FormData();
        for (const key in formData) {
          formDataToSend.append(key, formData[key]);
        }

        const response = await AdminService.updateEditor(formDataToSend);
        toast.success(response.message);
        navigate("/user/editorialList");
      } catch (error) {
        toast.error("Error updating Editor Board");
      }
    }
  };

  const getJournalNames = async () => {
    try {
      const response = await AdminService.allJournalNames("");
      const options = response.data.map((type: any) => ({
        value: type.journalName,
        label: type.journalName,
        id: type._id,
      }));
      setJournalOptions(options);
    } catch (error) {
      console.error("Error fetching journal names:", error);
    }
  };

  const handleCancel = () => {
    navigate("/user/editorialList");
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <Header />
        </div>
        <div className="row">
          <div className="col-md-2 h-100 px-0">
            <Sidebar />
          </div>
          <div className="col-md-10 content-scroll content-bg px-3 py-3">
            <h3>Edit Editorial Board</h3>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Name *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor Name"
                      name="editorName"
                      value={formData.editorName}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Email *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor Email"
                      name="editorEmail"
                      value={formData.editorEmail}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorEmail}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorEmail}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Contact Number *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor Contact Number"
                      name="editorContactNubmer"
                      value={formData.editorContactNubmer}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorContactNubmer}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorContactNubmer}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Affiliation *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor Affiliation"
                      name="editorAffiliation"
                      value={formData.editorAffiliation}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorAffiliation}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorAffiliation}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Department *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor Department"
                      name="editorDepartment"
                      value={formData.editorDepartment}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorDepartment}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorDepartment}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor University Name *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor University Name"
                      name="editorUniversityName"
                      value={formData.editorUniversityName}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorUniversityName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorUniversityName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Country Name *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor Country Name"
                      name="editorCountryName"
                      value={formData.editorCountryName}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorCountryName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorCountryName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Type *</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Editor Type"
                      name="editorType"
                      value={formData.editorType}
                      onChange={handleInputChange}
                      isInvalid={!!errors.editorType}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorType}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Editor Journal Name *</Form.Label>
                    <Form.Control
                      type="text"
                      name="editorJournalName"
                      value={userData?.journalName?.journalName}
                      disabled
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mt-3">
                    <Form.Label>Meta Title</Form.Label>
                    <Form.Control
                      type="text"
                      name="metaTitle"
                      value={formData.metaTitle}
                      onChange={handleInputChange}
                      placeholder="Meta Title"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mt-3">
                    <Form.Label>Meta Description</Form.Label>
                    <Form.Control
                      type="text"
                      name="metaDescription"
                      value={formData.metaDescription}
                      onChange={handleInputChange}
                      placeholder="Meta Description"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mt-3">
                    <Form.Label>Slug</Form.Label>
                    <Form.Control
                      type="text"
                      name="slug"
                      value={formData.slug}
                      onChange={handleInputChange}
                      placeholder="Slug"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mt-3">
                    <Form.Label>Editor Biography</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="editorBiography"
                      value={formData.editorBiography}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mt-3">
                    <Form.Label>Editor Research Interest</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="editorResearchInterest"
                      value={formData.editorResearchInterest}
                      onChange={handleInputChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mt-3">
                    <Form.Label>Editor Image *</Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/*" // Restrict file types to images
                      onChange={handleImageChange}
                      isInvalid={!!errors.editorImage}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.editorImage}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Button type="submit" className="mt-4 mb-4">Update</Button>
              <Button
                variant="secondary"
                onClick={handleCancel}
                className="ms-2 mt-4 mb-4"
              >
                Cancel
              </Button>
            </Form>

            {showToast && (
              <Toast
                onClose={() => setShowToast(false)}
                style={{ position: "absolute", top: "20px", right: "20px" }}
                bg={toastType}
                autohide
                delay={3000}
              >
                <Toast.Body>{toastMessage}</Toast.Body>
              </Toast>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEditorialBoard;
