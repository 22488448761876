import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AdminService } from "../../../app/service/admin.service";
import { Header } from "../../dashboard/dashboardHeader";
import { Sidebar } from "../../dashboard/sidebar";
import { Form, Button, Col } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CreateHeaderImage = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<any>({
    description: "",
    headerImage: null,
    aim: "",
    vision: "",
    metaTitle: "",
    metaDescription: "",
    slug: "",
  });

  const [errors, setErrors] = useState<any>({});

  const handleInput = (e: any, selectedOption?: any) => {
    if (selectedOption) {
      // Handle react-select input
      const { value, id } = selectedOption; // Extract both value and id
      setFormData((prevState: any) => ({
        ...prevState,
        journalTitle: value,
        journalNameId: id, // Set the selected journal ID
      }));
    } else if (e.target.type === "file") {
      // Handle file input
      const { name, files } = e.target;
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: files[0], // Only handle single file upload
      }));
    } else {
      // Handle standard input fields
      const { name, value } = e.target;
      setFormData((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }

    // Reset errors for the field being changed
    setErrors((prevErrors: any) => ({
      ...prevErrors,
      [e?.target?.name || selectedOption?.name]: "",
    }));
  };

  const handleEditorChange = (event: any, editor: any) => {
    const data = editor.getData();
    setFormData({
      ...formData,
      description: data,
    });
  };

  const validate = () => {
    const newErrors: any = {};

    if (!formData.description) {
      newErrors.description = "Description is required";
    }

    if (!formData.headerImage) {
      newErrors.headerImage = "Header Image is required";
    }

    if (!formData.vision) {
      newErrors.vision = "Vision is required";
    }

    if (!formData.aim) {
      newErrors.aim = "Aim is required";
    }

    

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleRegister = (e: any) => {
    e.preventDefault();
    const loginData: any = sessionStorage.getItem("loginData");
    const data = JSON.parse(loginData);

    formData.userName = data.email;

    if (validate()) {
      const formDataToSubmit = new FormData();
      Object.keys(formData).forEach((key) => {
        formDataToSubmit.append(key, formData[key]);
      });

      AdminService.createHeaderData(formDataToSubmit).then((res) => {
        if (res.status === "Fail") {
          toast.error(res.error);
        } else {
          toast.success(res.message);
          navigate("/admin/HeaderImagesList");
        }
      });
    }
  };

  const handleCancel = () => {
    navigate("/admin/HeaderImagesList");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <Header />
      </div>
      <div className="row">
        <div className="col-md-2 h-100 px-0">
          <Sidebar />
        </div>
        <div className="col-md-10 content-scroll content-bg px-3 py-3">
          <h4 style={{ textAlign: "left" }}>
            <b>Add New Header Image</b>
          </h4>
          <Form>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="headerImage" className="mt-3">
                  <Form.Label>Header Image *</Form.Label>
                  <Form.Control
                    type="file"
                    name="headerImage"
                    onChange={handleInput}
                    className={errors.headerImage ? "is-invalid" : ""}
                  />
                  {errors.headerImage && (
                    <div className="invalid-feedback">{errors.headerImage}</div>
                  )}
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group className="mt-3" controlId="aim">
                  <Form.Label className="aim">Aim *</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter Aim"
                    name="aim"
                    value={formData.aim} // Set value here
                    onChange={handleInput}
                  />
                  {errors.aim && (
                    <div className="text-danger">{errors.aim}</div>
                  )}
                </Form.Group>
              </div>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>Meta Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="metaTitle"
                    value={formData.metaTitle}
                    onChange={handleInput}
                    placeholder="Meta Title"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>Meta Description</Form.Label>
                  <Form.Control
                    type="text"
                    name="metaDescription"
                    value={formData.metaDescription}
                    onChange={handleInput}
                    placeholder="Meta Description"
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mt-3">
                  <Form.Label>slug</Form.Label>
                  <Form.Control
                    type="text"
                    name="slug"
                    value={formData.slug}
                    onChange={handleInput}
                    placeholder="Slug"
                  />
                </Form.Group>
              </Col>
              <div className="col-md-12">
                <Form.Group className="mt-3" controlId="vision">
                  <Form.Label className="vision">Mision *</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Enter Mision"
                    name="vision"
                    value={formData.vision} // Set value here
                    onChange={handleInput}
                  />
                  {errors.vision && (
                    <div className="text-danger">{errors.vision}</div>
                  )}
                </Form.Group>
              </div>
              <div className="col-md-12">
                <Form.Group className="mt-3">
                  <Form.Label>Description *</Form.Label>
                  <div className="custom-editor-height">
                    <CKEditor
                      editor={ClassicEditor}
                      data={formData.description}
                      onChange={handleEditorChange}
                    />
                  </div>
                  {errors.description && (
                    <div className="invalid-feedback d-block">
                      {errors.description}
                    </div>
                  )}
                </Form.Group>
              </div>
            </div>
            <div className="mt-3">
              <Button
                variant="primary"
                type="submit"
                className="me-2"
                onClick={handleRegister}
              >
                Create
              </Button>
              <Button variant="secondary" type="button" onClick={handleCancel}>
                Cancel
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default CreateHeaderImage;
